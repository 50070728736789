// DON'T EDIT. THIS FILE IS GENERATED BY ./manage.py frontend_export
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// mynamespace/contribs/management/commands/frontend_export.py

const CHOICES = {
  AccountKind: {
    CHOICES: {
      1: "Individual",
      2: "Corporate",
    },
    CHOICE_LIST: [
      {
        label: "Individual",
        value: 1,
      },
      {
        label: "Corporate",
        value: 2,
      },
    ],
    CORPORATE: 2,
    INDIVIDUAL: 1,
  },
  AccountingType: {
    CHOICES: {
      1: "First In First Out",
      2: "Last In First Out",
    },
    CHOICE_LIST: [
      {
        label: "First In First Out",
        value: 1,
      },
      {
        label: "Last In First Out",
        value: 2,
      },
    ],
    FIFO: 1,
    LIFO: 2,
  },
  AnalyticsStatus: {
    ACCOUNTOPENED_FUNDING: 4,
    CHOICES: {
      1: "PROFILESENT_DOCUMENTSENT",
      2: "DOCUMENTSENT_DOCUMENTRECEIVED",
      3: "DOCUMENTRECEIVED_ACCOUNTOPENED",
      4: "ACCOUNTOPENED_FUNDING",
    },
    CHOICE_LIST: [
      {
        label: "PROFILESENT_DOCUMENTSENT",
        value: 1,
      },
      {
        label: "DOCUMENTSENT_DOCUMENTRECEIVED",
        value: 2,
      },
      {
        label: "DOCUMENTRECEIVED_ACCOUNTOPENED",
        value: 3,
      },
      {
        label: "ACCOUNTOPENED_FUNDING",
        value: 4,
      },
    ],
    DOCUMENTRECEIVED_ACCOUNTOPENED: 3,
    DOCUMENTSENT_DOCUMENTRECEIVED: 2,
    PROFILESENT_DOCUMENTSENT: 1,
  },
  AssetKind: {
    CASH: 2,
    CHOICES: {
      1: "Equity",
      2: "Cash & Equivalent",
      3: "Fixed Income",
      4: "Other Assets",
    },
    CHOICE_LIST: [
      {
        label: "Equity",
        value: 1,
      },
      {
        label: "Cash & Equivalent",
        value: 2,
      },
      {
        label: "Fixed Income",
        value: 3,
      },
      {
        label: "Other Assets",
        value: 4,
      },
    ],
    EQUITY: 1,
    INCOME: 3,
    OTHER: 4,
  },
  AuditEntities: {
    CHOICES: {
      1: "Client",
      2: "Distributor",
      3: "User",
      4: "Family",
      5: "Transaction",
      6: "Ficlient",
      7: "REVENUE SETTINGS",
    },
    CHOICE_LIST: [
      {
        label: "Client",
        value: 1,
      },
      {
        label: "Distributor",
        value: 2,
      },
      {
        label: "User",
        value: 3,
      },
      {
        label: "Family",
        value: 4,
      },
      {
        label: "Transaction",
        value: 5,
      },
      {
        label: "Ficlient",
        value: 6,
      },
      {
        label: "REVENUE SETTINGS",
        value: 7,
      },
    ],
    CLIENT: 1,
    DISTRIBUTOR: 2,
    FAMILY: 4,
    FICLIENT: 6,
    REVENUESETTINGS: 7,
    TRANSACTION: 5,
    USER: 3,
  },
  AuditReportKind: {
    AUDITORS_COVERING_LETTER: 1,
    BALANCE_SHEET: 3,
    CAPITAL_GAIN_LOSS_STATEMENT: 6,
    CHOICES: {
      1: "Auditor's Covering Letter",
      2: "Profit & Loss Statement",
      3: "Balance Sheet",
      4: "Current Portfolio ",
      5: "Transaction Statement",
      6: "Capital Gain Loss Statement",
      7: "Statement of Dividend",
      8: "Statement of Interest",
      9: "Statement of Expense",
      10: "Notes to accounts",
      11: "Itus's Covering Letter",
      // 12: "Merged Audit Report",
    },
    CHOICE_LIST: [
      {
        label: "Itus's Covering Letter",
        value: 11,
      },
      {
        label: "Auditor's Covering Letter",
        value: 1,
      },
      // {
      //   label: "Profit & Loss Statement",
      //   value: 2,
      // },
      // {
      //   label: "Balance Sheet",
      //   value: 3,
      // },
      {
        label: "Current Portfolio ",
        value: 4,
      },
      {
        label: "Transaction Statement",
        value: 5,
      },
      {
        label: "Capital Gain Loss Statement",
        value: 6,
      },
      {
        label: "Statement of Dividend",
        value: 7,
      },
      {
        label: "Statement of Interest",
        value: 8,
      },
      {
        label: "Statement of Expense",
        value: 9,
      },
      {
        label: "Notes to accounts",
        value: 10,
      },

      // {
      //   label: "Merged Audit Report",
      //   value: 12,
      // },
    ],
    ITUS_COVERING_LETTER: 11,
    // MERGED_AUDIT_REPORT: 12,
    NOTES_TO_ACCOUNTS: 10,
    CURRENT_PORTFOLIO: 4,
    // PROFIT_AND_LOSS_STATEMENT: 2,
    STATEMENT_OF_DIVIDEND: 7,
    STATEMENT_OF_EXPENSE: 9,
    STATEMENT_OF_INTEREST: 8,
    TRANSACTION_STATEMENT: 5,
  },
  ClientAuditReportMailerStatus: {
    CHOICES: {
      1: "Registered",
      2: "Intiated",
      3: "Sent",
      4: "Notsent",
    },
    CHOICE_LIST: [
      {
        label: "Registered",
        value: 1,
      },
      {
        label: "Intiated",
        value: 2,
      },
      {
        label: "Sent",
        value: 3,
      },
      {
        label: "Notsent",
        value: 4,
      },
    ],
    INITIATED: 2,
    NOT_SENT: 4,
    REGISTERED: 1,
    SENT: 3,
  },
  ClientReports: {
    CHOICES: {
      1: "Fact Sheet",
      2: "Taxation Reports",
      3: "Transaction Details",
    },
    CHOICE_LIST: [
      {
        label: "Fact Sheet",
        value: 1,
      },
      {
        label: "Taxation Reports",
        value: 2,
      },
      {
        label: "Transaction Details",
        value: 3,
      },
    ],
    FACTSHEET: 1,
    TAXATION_REPORTS: 2,
    TRANSACTION_DETAILS: 3,
  },
  CustodianChoices: {
    CHOICES: {
      1: "KOTAK",
      2: "HDFC",
    },
    CHOICE_LIST: [
      {
        label: "KOTAK",
        value: 1,
      },
      {
        label: "HDFC",
        value: 2,
      },
    ],
    HDFC: 2,
    KOTAK: 1,
  },
  DailyReports: {
    AMC_LEVEL_NAV: 1,
    CHOICES: {
      1: "AMC LEVEL NAV",
      2: "Portfolio Summary With Security Percentage",
      3: "Holding Client",
      4: "Transaction Report Client",
      5: "Trial Balance Client",
      6: "Portfolio Fact Sheet",
      7: "Holding Client BQ",
      8: "Holding Global BQ",
      9: "Portfolio PerfSummary Client",
    },
    CHOICE_LIST: [
      {
        label: "AMC LEVEL NAV",
        value: 1,
      },
      {
        label: "Portfolio Summary With Security Percentage",
        value: 2,
      },
      {
        label: "Holding Client",
        value: 3,
      },
      {
        label: "Transaction Report Client",
        value: 4,
      },
      {
        label: "Trial Balance Client",
        value: 5,
      },
      {
        label: "Portfolio Fact Sheet",
        value: 6,
      },
      {
        label: "Holding Client BQ",
        value: 7,
      },
      {
        label: "Holding Global BQ",
        value: 8,
      },
      {
        label: "Portfolio PerfSummary Client",
        value: 9,
      },
    ],
    HOLDING_CLIENT: 3,
    HOLDING_CLIENT_BQ: 7,
    HOLDING_GLOBAL_BQ: 8,
    PORTFOLIO_FACT_SHEET: 6,
    PORTFOLIO_PERFSUMMARY_CLIENT: 9,
    PORTFOLIO_SUMMARY_WITH_SECURITY_PERCENTAGE: 2,
    TRANSACTION_REPORT_CLIENT: 4,
    TRIAL_BALANCE_CLIENT: 5,
  },
  EmailTrackingStatus: {
    CHOICES: {
      1: "Yes",
      2: "No",
      3: "Not Required",
    },
    CHOICE_LIST: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 2,
      },
      {
        label: "Not Required",
        value: 3,
      },
    ],
    NO: 2,
    NOT_REQUIRED: 3,
    YES: 1,
  },
  FinanceKind: {
    CASH: 2,
    CHOICES: {
      1: "Stock",
      2: "Cash",
      3: "Stock & Cash",
    },
    CHOICE_LIST: [
      {
        label: "Stock",
        value: 1,
      },
      {
        label: "Cash",
        value: 2,
      },
      {
        label: "Stock & Cash",
        value: 3,
      },
    ],
    STOCK: 1,
    STOCK_CASH: 3,
  },
  Frequency: {
    ANNIVERSARY: 5,
    ANNUAL: 4,
    CHOICES: {
      1: "Monthly",
      2: "Quarterly",
      3: "Half Yearly",
      4: "Annual",
      5: "Anniversary",
    },
    CHOICE_LIST: [
      {
        label: "Monthly",
        value: 1,
      },
      {
        label: "Quarterly",
        value: 2,
      },
      {
        label: "Half Yearly",
        value: 3,
      },
      {
        label: "Annual",
        value: 4,
      },
      {
        label: "Anniversary",
        value: 5,
      },
    ],
    HALFYEARLY: 3,
    MONTHLY: 1,
    QUARTERLY: 2,
  },
  MISMailerStatus: {
    CHOICES: {
      1: "Registered",
      2: "Intiated",
      3: "Sent",
      4: "Notsent",
    },
    CHOICE_LIST: [
      {
        label: "Registered",
        value: 1,
      },
      {
        label: "Intiated",
        value: 2,
      },
      {
        label: "Sent",
        value: 3,
      },
      {
        label: "Notsent",
        value: 4,
      },
    ],
    INITIATED: 2,
    NOT_SENT: 4,
    REGISTERED: 1,
    SENT: 3,
  },
  MarketCap: {
    CHOICES: {
      1: "Large",
      2: "Mid",
      3: "Small",
    },
    CHOICE_LIST: [
      {
        label: "Large",
        value: 1,
      },
      {
        label: "Mid",
        value: 2,
      },
      {
        label: "Small",
        value: 3,
      },
    ],
    LARGE: 1,
    MID: 2,
    SMALL: 3,
  },
  ModeKind: {
    CASH: 1,
    CHOICES: {
      1: "Cash",
      2: "Stock",
    },
    CHOICE_LIST: [
      {
        label: "Cash",
        value: 1,
      },
      {
        label: "Stock",
        value: 2,
      },
    ],
    STOCK: 2,
  },
  Nationality: {
    CHOICES: {
      1: "Resident",
      2: "NRI",
    },
    CHOICE_LIST: [
      {
        label: "Resident",
        value: 1,
      },
      {
        label: "NRI",
        value: 2,
      },
    ],
    NRI: 2,
    RESIDENT: 1,
  },
  NewprospectStatus: {
    ACCOUNTOPENED: 3,
    CHOICES: {
      1: "WAITINGFORPROFILE",
      2: "WAITINGFORDOCUMENT",
      3: "ACCOUNTOPENED",
      4: "FUNDING",
      5: "COMPLETE",
      6: "LOST",
    },
    CHOICE_LIST: [
      {
        label: "WAITINGFORPROFILE",
        value: 1,
      },
      {
        label: "WAITINGFORDOCUMENT",
        value: 2,
      },
      {
        label: "ACCOUNTOPENED",
        value: 3,
      },
      {
        label: "FUNDING",
        value: 4,
      },
      {
        label: "COMPLETE",
        value: 5,
      },
      {
        label: "LOST",
        value: 6,
      },
    ],
    COMPLETE: 5,
    FUNDING: 4,
    LOST: 6,
    WAITINGFORDOCUMENT: 2,
    WAITINGFORPROFILE: 1,
  },
  PortfolioKind: {
    CHOICES: {
      1: "Equity",
      2: "Discretionary",
      3: "Non-Discretionary",
    },
    CHOICE_LIST: [
      {
        label: "Equity",
        value: 1,
      },
      {
        label: "Discretionary",
        value: 2,
      },
      {
        label: "Non-Discretionary",
        value: 3,
      },
    ],
    DISCRETIONARY: 2,
    EQUITY: 1,
    NONDISCRETIONARY: 3,
  },
  ProspectStatus: {
    CHOICES: {
      1: "Open",
      2: "Won",
      3: "Lost",
    },
    CHOICE_LIST: [
      {
        label: "Open",
        value: 1,
      },
      {
        label: "Won",
        value: 2,
      },
      {
        label: "Lost",
        value: 3,
      },
    ],
    LOST: 3,
    OPEN: 1,
    WON: 2,
  },
  ProspectStatus1: {
    ACCOUNTOPENED: 3,
    CHOICES: {
      1: "WAITINGFORPROFILE",
      2: "WAITINGFORDOCUMENTS",
      3: "ACCOUNTOPENED",
      4: "FUNDING",
      5: "COMPLETE",
    },
    CHOICE_LIST: [
      {
        label: "WAITINGFORPROFILE",
        value: 1,
      },
      {
        label: "WAITINGFORDOCUMENTS",
        value: 2,
      },
      {
        label: "ACCOUNTOPENED",
        value: 3,
      },
      {
        label: "FUNDING",
        value: 4,
      },
      {
        label: "COMPLETE",
        value: 5,
      },
    ],
    COMPLETE: 5,
    FUNDING: 4,
    WAITINGFORDOCUMENTS: 2,
    WAITINGFORPROFILE: 1,
  },
  Quarter: {
    CHOICES: {
      1: "Q1 (Apr - Jun)",
      2: "Q2 (Jul - Sep)",
      3: "Q3 (Oct - Dec)",
      4: "Q4 (Jan - Mar)",
    },
    CHOICE_LIST: [
      {
        label: "Q1 (Apr - Jun)",
        value: 1,
      },
      {
        label: "Q2 (Jul - Sep)",
        value: 2,
      },
      {
        label: "Q3 (Oct - Dec)",
        value: 3,
      },
      {
        label: "Q4 (Jan - Mar)",
        value: 4,
      },
    ],
    Q1: 1,
    Q2: 2,
    Q3: 3,
    Q4: 4,
  },
  RedemptionKind: {
    CHOICES: {
      1: "Partial",
      2: "Full",
    },
    CHOICE_LIST: [
      {
        label: "Partial",
        value: 1,
      },
      {
        label: "Full",
        value: 2,
      },
    ],
    FULL: 2,
    PARTIAL: 1,
  },
  RevenueShareTypeChoices: {
    CHOICES: {
      1: "Initial Inflow",
      2: "Top Up",
      3: "Partial Redemption",
      4: "Redemption",
      5: "Gain/Loss",
    },
    CHOICE_LIST: [
      {
        label: "Initial Inflow",
        value: 1,
      },
      {
        label: "Top Up",
        value: 2,
      },
      {
        label: "Partial Redemption",
        value: 3,
      },
      {
        label: "Redemption",
        value: 4,
      },
      {
        label: "Gain/Loss",
        value: 5,
      },
    ],
    GAIN_OR_LOSS: 5,
    INITIAL_INFLOW: 1,
    PARTIAL_REDEMPTION: 3,
    REDEMPTION: 4,
    TOP_UP: 2,
  },
  SecurityTransactionAssetType: {
    CHOICES: {
      1: "Shares",
      2: "Mutual Funds",
      3: "Other Assets",
    },
    CHOICE_LIST: [
      {
        label: "Shares",
        value: 1,
      },
      {
        label: "Mutual Funds",
        value: 2,
      },
      {
        label: "Other Assets",
        value: 3,
      },
    ],
    MUTUAL_FUNDS: 2,
    OTHER_ASSETS: 3,
    SHARES: 1,
  },
  TaxSlab: {
    CHOICES: {
      1: "Senior Citizen",
      2: "Individual",
      3: "Corporate",
    },
    CHOICE_LIST: [
      {
        label: "Senior Citizen",
        value: 1,
      },
      {
        label: "Individual",
        value: 2,
      },
      {
        label: "Corporate",
        value: 3,
      },
    ],
    CORPORATE: 3,
    INDIVIDUAL: 2,
    SENIOR_CITIZEN: 1,
  },
  TaxationOptions: {
    CHOICES: {
      1: "Q1 (Apr - Jun)",
      2: "Q2 (Jul - Sep)",
      3: "Q3 (Oct - Dec)",
      4: "Q4 (Jan - Mar)",
      5: "Advance Taxes",
    },
    CHOICE_LIST: [
      {
        label: "Q1 (Apr - Jun)",
        value: 1,
      },
      {
        label: "Q2 (Jul - Sep)",
        value: 2,
      },
      {
        label: "Q3 (Oct - Dec)",
        value: 3,
      },
      {
        label: "Q4 (Jan - Mar)",
        value: 4,
      },
      {
        label: "Advance Taxes",
        value: 5,
      },
    ],
    Q1: 1,
    Q2: 2,
    Q3: 3,
    Q4: 4,
    Q5: 5,
  },
  TaxationUploadKind: {
    CAPITAL_GAIN_LOSS: 1,
    CHOICES: {
      1: "Capital Gain/Loss Statement",
      2: "Interests Statement",
      3: "Dividends Statement",
      4: "PMS Investor Report",
    },
    CHOICE_LIST: [
      {
        label: "Capital Gain/Loss Statement",
        value: 1,
      },
      {
        label: "Interests Statement",
        value: 2,
      },
      {
        label: "Dividends Statement",
        value: 3,
      },
      {
        label: "PMS Investor Report",
        value: 4,
      },
    ],
    DIVIDEND_STATEMENT: 3,
    INTEREST_STATEMENT: 2,
    PMS_INVESTOR_REPORT: 4,
  },
  TransactionKind: {
    CHOICES: {
      1: "Inflow",
      2: "Outflow",
    },
    CHOICE_LIST: [
      {
        label: "Inflow",
        value: 1,
      },
      {
        label: "Outflow",
        value: 2,
      },
    ],
    INFLOW: 1,
    OUTFLOW: 2,
  },
  UploadKind: {
    AUDIT_REPORT: 16,
    CHOICES: {
      1: "Daily Reports Zip",
      2: "Kotak Timeline Tracking",
      3: "Management Fee",
      4: "Performance Fee",
      5: "Custody Fee",
      6: "Market Cap",
      7: "Taxation Report",
      8: "Entitlement Report",
      9: "Internal Stock Allocation",
      10: "Kotak Stock Allocation",
      11: "Fund Portfolio",
      12: "FPI Reports",
      13: "Client Emails",
      14: "Clients Factsheet",
      15: "Distributor MIS",
      16: "Audit Report",
      17: "Client Signed Audit Report",
    },
    CHOICE_LIST: [
      {
        label: "Daily Reports Zip",
        value: 1,
      },
      {
        label: "Kotak Timeline Tracking",
        value: 2,
      },
      {
        label: "Management Fee",
        value: 3,
      },
      {
        label: "Performance Fee",
        value: 4,
      },
      {
        label: "Custody Fee",
        value: 5,
      },
      {
        label: "Market Cap",
        value: 6,
      },
      {
        label: "Taxation Report",
        value: 7,
      },
      {
        label: "Entitlement Report",
        value: 8,
      },
      {
        label: "Internal Stock Allocation",
        value: 9,
      },
      {
        label: "Kotak Stock Allocation",
        value: 10,
      },
      {
        label: "Fund Portfolio",
        value: 11,
      },
      {
        label: "FPI Reports",
        value: 12,
      },
      {
        label: "Client Emails",
        value: 13,
      },
      {
        label: "Clients Factsheet",
        value: 14,
      },
      {
        label: "Distributor MIS",
        value: 15,
      },
      {
        label: "Audit Report",
        value: 16,
      },
      {
        label: "Client Signed Audit Report",
        value: 17,
      },
    ],
    CLIENTS_FACTSHEET: 14,
    CLIENTS_SIGNED_AUDIT_REPORT: 17,
    CLIENT_EMAILS: 13,
    CUSTODY_FEE: 5,
    DAILY_REPORTS_ZIP: 1,
    DISTRIBUTOR_MIS_REPORT: 15,
    ENTITLEMENT: 8,
    FPI_REPORTS: 12,
    FUND_PORTFOLIO: 11,
    INTERNAL_STOCK_ALLOCATION: 9,
    KOTAK_STOCK_ALLOCATION: 10,
    KOTAK_TIMELINE_TRACKING: 2,
    MANAGEMENT_FEE: 3,
    MARKET_CAP: 6,
    PERFORMANCE_FEE: 4,
    TAXATION: 7,
  },
  UploadStatus: {
    CHOICES: {
      1: "Uploading",
      2: "Uploaded",
      3: "Processing",
      4: "Processed",
      5: "Error",
    },
    CHOICE_LIST: [
      {
        label: "Uploading",
        value: 1,
      },
      {
        label: "Uploaded",
        value: 2,
      },
      {
        label: "Processing",
        value: 3,
      },
      {
        label: "Processed",
        value: 4,
      },
      {
        label: "Error",
        value: 5,
      },
    ],
    ERROR: 5,
    PROCESSED: 4,
    PROCESSING: 3,
    UPLOADED: 2,
    UPLOADING: 1,
  },
  UserKind: {
    ADMIN: 2,
    CHOICES: {
      1: "Super Admin",
      2: "Admin",
      3: "Client",
      4: "Distributor",
    },
    CHOICE_LIST: [
      {
        label: "Super Admin",
        value: 1,
      },
      {
        label: "Admin",
        value: 2,
      },
      {
        label: "Client",
        value: 3,
      },
      {
        label: "Distributor",
        value: 4,
      },
    ],
    CLIENT: 3,
    DISTRIBUTOR: 4,
    SUPER_ADMIN: 1,
  },
};

export default CHOICES;
